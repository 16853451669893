@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: #000000;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
  }
}

@media print {
  body {
    padding: 30px;
  }
}
